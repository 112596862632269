<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    modelValue: { // Renamed to modelValue for clarity
        type: Boolean,
        default: false,
    }
});

const isChecked = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:checked', value);
    },
});
</script>

<template>
    <input
        type="checkbox"
        :checked="isChecked"
        @change="isChecked = $event.target.checked"
        class="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
    >
</template>
